<template>
	<v-card dark class="backBlueDarken backBlueLight--text" outlined elevation="3">
		<v-card-title class="blue-grey darken-2">COMMAND</v-card-title>
		<v-card-text>
			<div class="mt-4">
				<v-form ref="commForm" v-model="formValidity">
					<v-textarea outlined v-model="commandField" append-icon="mdi-access-point-network" label="Command" rows="2" dense :rules="textRules" />
				</v-form>
				<v-alert  dense color="blue-grey">{{ responseField }}</v-alert>
				<v-alert class="mt-2" dense type="success" v-if="successful"> Command saved successful</v-alert>
			</div>
		</v-card-text>
		<v-card-actions class="blue-grey darken-2">
			<v-btn :disabled="!formValidity" dense color="primary" @click="putCommand()" :loading="sending">
				SAVE
			</v-btn>
			<v-btn dense color="secondary" @click="getInfo()" outlined >
				REFRESH
			</v-btn>
		</v-card-actions>
	</v-card>

</template>
<script>
import { dbFb } from '@/main';

export default {
	name: 'putCommand',
	data: () => ({
		commandField: '',
		responseField: '',
		formValidity: false,
		textRules: [v => !!v || "This field is required"],
		successful: false,
		sending: false
	}),
	mounted() {

		this.getInfo()
	},
	methods: {

		getInfo() {
			let me = this
			let _imei = this.$route.params.imei
			dbFb.ref(`/${_imei}/info`).once('value')
			.then(function (snap) {
				if (snap.exists){
					const info = snap.val()
					if ( info.command && info.command != '') {
						me.commandField = info.command
					}
					if ( info.response && info.response != '') {
						me.responseField = info.response
					}
				}
			})
		},
		putCommand() {
			let me = this
			if (this.commandField != null && this.commandField != ''){
				let me = this
				let _imei = this.$route.params.imei
				me.saving = true
				dbFb.ref(`/${_imei}/info`).update({
					command: me.commandField,
				}).then(docRef => {
					me.successful = true
					me.getInfo()
					setTimeout(() => me.successful = false, 3000);
				}).catch(function (error) {
						console.log("Error :", error);
					})
					.finally(() => { me.saving = false });



			}
		}

	},
}
</script>